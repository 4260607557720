import React from 'react';
import CountUp from 'react-countup';
import {
  Box,
  Typography,
  Paper,
  Grid,
  useTheme,
  Avatar,
} from '@mui/material';
import { supabase } from '../lib/supabaseClient';
import ForestIcon from '@mui/icons-material/Forest';
import AcUnitIcon from '@mui/icons-material/AcUnit';

// Environmental impact constants (all values in kg CO2)
const IMPACT_CONSTANTS = {
  // Tree & Carbon
  CARBON_PER_TREE: 22, // kg CO2 per year per tree (conservative estimate)
  
  // Aviation
  DUBAI_LONDON_FLIGHT: 930, // Round trip per passenger
  DUBAI_PARIS_FLIGHT: 880, // Round trip per passenger
  HELICOPTER_TOUR: 120, // 30-minute Dubai helicopter tour
  
  // Transportation
  DUBAI_ABU_DHABI: 26.6, // 133km one-way trip at 0.2kg/km
  DUBAI_MALL_DRIVE: 0.6, // 3km loop at 0.2kg/km
  F1_LAP: 5.2, // One lap at Yas Marina Circuit
  SUPERCAR_HOUR: 12.8, // One hour of supercar driving experience
  
  // Building & Infrastructure
  BURJ_ELEVATOR: 0.012, // Per trip to observation deck
  METRO_TRIP: 0.048, // Per passenger per 10km trip
};

const LiveDashboard = () => {
  const theme = useTheme();
  const [treeCount, setTreeCount] = React.useState(null);
  const [recentPlanters, setRecentPlanters] = React.useState([]);
  const carbonOffset = treeCount ? treeCount * IMPACT_CONSTANTS.CARBON_PER_TREE : 0;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to generate avatar color based on name and index
  const getAvatarColor = (name, index) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Bright green for most recent
    if (index === 0) {
      return '#2E7D32';
    }

    // Consistent color for older entries
    return '#90A4AE';
  };

  // Function to get initials from name
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  // Load initial data and setup real-time subscription
  React.useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Get tree count
        const { count: treeCount } = await supabase
          .from('tree_plantings')
          .select('*', { count: 'exact', head: true });

        setTreeCount(treeCount || 0);

        // Get recent planters
        const { data: recentData } = await supabase
          .from('tree_plantings')
          .select('name, created_at')
          .order('created_at', { ascending: false })
          .limit(5);

        if (recentData) {
          setRecentPlanters(recentData);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();

    // Subscribe to new tree plantings
    const subscription = supabase
      .channel('tree_plantings')
      .on('postgres_changes', 
        { event: 'INSERT', schema: 'public', table: 'tree_plantings' },
        (payload) => {
          // Update tree count
          setTreeCount((prev) => (prev || 0) + 1);

          // Update recent planters
          setRecentPlanters((prev) => [{
            name: payload.new.name,
            created_at: payload.new.created_at
          }, ...prev.slice(0, 4)]);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const calculateDetailedImpact = (trees) => {
    const carbonKg = trees * IMPACT_CONSTANTS.CARBON_PER_TREE;
    
    return {
      carbonKg: Math.round(carbonKg),
      dubaiLondonFlights: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_LONDON_FLIGHT * 10) / 10,
      dubaiParisFlights: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_PARIS_FLIGHT * 10) / 10,
      helicopterTours: Math.round(carbonKg / IMPACT_CONSTANTS.HELICOPTER_TOUR),
      f1Laps: Math.round(carbonKg / IMPACT_CONSTANTS.F1_LAP),
      supercarHours: Math.round(carbonKg / IMPACT_CONSTANTS.SUPERCAR_HOUR),
      dubaiAbuDhabiTrips: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_ABU_DHABI),
    };
  };

  const getRelevantImpactStatements = (impact) => {
    const statements = [];
    
    // Add statements based on significance
    if (impact.dubaiLondonFlights >= 0.5) {
      statements.push({
        icon: '✈️',
        primary: `${impact.dubaiLondonFlights} Dubai-London flights`,
        secondary: 'Round trips offset'
      });
    }

    if (impact.helicopterTours >= 1) {
      statements.push({
        icon: '🚁',
        primary: `${impact.helicopterTours} helicopter tours`,
        secondary: 'Dubai aerial experiences'
      });
    }
    
    if (impact.f1Laps >= 10) {
      statements.push({
        icon: '🏎️',
        primary: `${impact.f1Laps} F1 laps`,
        secondary: 'At Yas Marina Circuit'
      });
    }

    if (impact.supercarHours >= 1) {
      statements.push({
        icon: '🏁',
        primary: `${impact.supercarHours} hours of supercar driving`,
        secondary: 'Luxury car experiences'
      });
    }
    
    if (impact.dubaiAbuDhabiTrips >= 5) {
      statements.push({
        icon: '🚗',
        primary: `${impact.dubaiAbuDhabiTrips} Dubai-Abu Dhabi trips`,
        secondary: 'Intercity drives offset'
      });
    }

    // If we have less than 5 statements, add alternative statements
    if (statements.length < 5 && impact.dubaiParisFlights >= 0.5) {
      statements.push({
        icon: '🗼',
        primary: `${impact.dubaiParisFlights} Dubai-Paris flights`,
        secondary: 'Round trips offset'
      });
    }

    // Return only up to 5 most impactful statements
    return statements.slice(0, 5);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e8edf2 100%)',
        pt: { xs: 10, sm: 11 }, 
        pb: 2,
        px: 2,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box 
        maxWidth="xl" 
        sx={{ 
          mx: 'auto', 
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Header */}
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              color: '#666',
              fontWeight: 500,
              fontSize: '0.9rem',
              mb: 1
            }}
          >
            Campaign Middle East - Agency of the Year Awards 2024
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: '#2E7D32',
              fontWeight: 600,
              fontSize: '1.5rem'
            }}
          >
            Live Tree Planting Dashboard
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ flex: 1 }}>
          {/* Main Stats */}
          <Grid item xs={12} md={8} sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {/* Tree Count and Carbon Offset */}
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(10px)',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Grid container spacing={2}>
                  {/* Tree Count */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                      <ForestIcon sx={{ fontSize: 40, color: '#2E7D32', mb: 1 }} />
                      <Typography variant="h3" sx={{ fontWeight: 700, mb: 0.5, color: '#1a1a1a', fontSize: '2.5rem' }}>
                        {treeCount === null ? (
                          "Loading..."
                        ) : (
                          <CountUp
                            end={treeCount}
                            duration={2}
                            separator=","
                          />
                        )}
                      </Typography>
                      <Typography variant="h6" sx={{ color: '#666', mb: 1, fontSize: '1rem' }}>
                        Trees Planted Tonight
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Carbon Offset */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                      <AcUnitIcon sx={{ fontSize: 40, color: '#2E7D32', mb: 1 }} />
                      <Typography variant="h3" sx={{ fontWeight: 700, mb: 0.5, color: '#1a1a1a', fontSize: '2.5rem' }}>
                        <CountUp
                          end={carbonOffset}
                          duration={2}
                          separator=","
                          suffix=" kg"
                        />
                      </Typography>
                      <Typography variant="h6" sx={{ color: '#666', mb: 1, fontSize: '1rem' }}>
                        CO₂ Offset Per Year
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                {/* Local Equivalents */}
                <Typography variant="h6" sx={{ mb: 2, color: '#2E7D32', fontWeight: 600, textAlign: 'center', mt: 2 }}>
                  Environmental Impact
                </Typography>
                <Grid container spacing={2} sx={{ flex: 1 }}>
                  {getRelevantImpactStatements(calculateDetailedImpact(treeCount)).map((statement, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1.5,
                          height: '100%',
                          bgcolor: 'rgba(46, 125, 50, 0.04)',
                          border: '1px solid rgba(46, 125, 50, 0.1)',
                          borderRadius: 2,
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            bgcolor: 'rgba(46, 125, 50, 0.08)',
                            transform: 'translateY(-2px)',
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h2" sx={{ mb: 1, fontSize: '1.75rem' }}>
                            {statement.icon}
                          </Typography>
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              mb: 0.5,
                              fontSize: '1rem',
                              fontWeight: 600,
                              color: '#2E7D32'
                            }}
                          >
                            {statement.primary}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: '#666',
                              fontSize: '0.8rem'
                            }}
                          >
                            {statement.secondary}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Box>
          </Grid>

          {/* Live Feed */}
          <Grid item xs={12} md={4} sx={{ height: '100%' }}>
            <Paper
              elevation={3}
              sx={{
                bgcolor: '#fff',
                borderRadius: 4,
                p: 2,
                height: '100%',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="h5" sx={{ mb: 2, color: theme.palette.text.primary, fontSize: '1.25rem' }}>
                Live Feed
              </Typography>
              <Box sx={{ position: 'relative', flex: 1, overflow: 'auto' }}>
                {recentPlanters.map((planter, index) => (
                  <Box
                    key={planter.created_at}
                    sx={{
                      marginBottom: '1rem',
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '0.75rem'
                    }}
                  >
                    {/* Avatar */}
                    <Avatar
                      sx={{
                        width: 48,
                        height: 48,
                        bgcolor: getAvatarColor(planter.name, index),
                        color: '#fff',
                        fontSize: '1.25rem',
                        fontWeight: 600,
                        border: '3px solid #fff',
                        boxShadow: index === 0 
                          ? '0 4px 14px rgba(76, 175, 80, 0.3)'
                          : '0 4px 12px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      {getInitials(planter.name)}
                    </Avatar>

                    {/* Content */}
                    <Box sx={{ flex: 1 }}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1.5,
                          bgcolor: index === 0 
                            ? 'rgba(76, 175, 80, 0.12)'
                            : 'rgba(0, 0, 0, 0.03)',
                          borderRadius: 3,
                          border: `1px solid ${index === 0 
                            ? 'rgba(76, 175, 80, 0.2)'
                            : 'rgba(0, 0, 0, 0.08)'}`,
                          position: 'relative',
                          transition: 'all 0.3s ease',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: -8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: 0,
                            height: 0,
                            borderTop: '8px solid transparent',
                            borderBottom: '8px solid transparent',
                            borderRight: `8px solid ${index === 0 
                              ? 'rgba(76, 175, 80, 0.2)'
                              : 'rgba(0, 0, 0, 0.08)'}`
                          }
                        }}
                      >
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: index === 0 
                              ? theme.palette.success.main
                              : theme.palette.text.primary,
                            fontWeight: index === 0 ? 600 : 500,
                            fontSize: '1.1rem',
                            mb: 0.5
                          }}
                        >
                          {planter.name.split(' ')[0]} just planted a tree! 🌱
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: index === 0
                              ? theme.palette.success.dark
                              : theme.palette.text.secondary,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            opacity: index === 0 ? 0.9 : 0.7
                          }}
                        >
                          🕒
                          {new Date(planter.created_at).toLocaleTimeString()}
                        </Typography>
                      </Paper>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LiveDashboard;
