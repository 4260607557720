import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Tooltip,
  InputAdornment,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { supabase } from '../lib/supabaseClient';
import * as XLSX from 'xlsx';
import greenVantageLogo from '../assets/logo.png';

const InternalDashboard = () => {
  const [submissions, setSubmissions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('all');
  const [companies, setCompanies] = useState([]);
  const [linkedinData, setLinkedinData] = useState({});
  const [searchLoading, setSearchLoading] = useState({});
  const [monthlyRequestCount, setMonthlyRequestCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const MONTHLY_LIMIT = 25; // Hunter.io free plan limit

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch submissions from the database
  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('tree_plantings')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSubmissions(data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setNotification({
        open: true,
        message: 'Error fetching submissions',
        severity: 'error'
      });
    }
  };

  // Export to Excel function
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      submissions.map(({ id, created_at, ...rest }) => ({
        ...rest,
        submitted_at: new Date(created_at).toLocaleString(),
      }))
    );
    
    // Adjust column widths
    const columnWidths = [
      { wch: 20 }, // name
      { wch: 25 }, // email
      { wch: 20 }, // company
      { wch: 40 }, // message
      { wch: 20 }, // submitted_at
    ];
    worksheet['!cols'] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Submissions');
    
    // Generate filename with current date
    const date = new Date().toISOString().split('T')[0];
    const filename = `tree-planting-submissions-${date}.xlsx`;
    
    XLSX.writeFile(workbook, filename);
    
    setNotification({
      open: true,
      message: 'Successfully exported to Excel!',
      severity: 'success'
    });
  };

  // Search for LinkedIn profile
  const searchLinkedInProfile = async (email, name, company) => {
    if (monthlyRequestCount >= MONTHLY_LIMIT) {
      setNotification({
        open: true,
        message: 'Monthly limit reached (25 searches). Please try again next month.',
        severity: 'warning'
      });
      return null;
    }

    setSearchLoading(prev => ({ ...prev, [email]: true }));
    
    try {
      // First try to find by domain
      const domain = email.split('@')[1];
      const response = await fetch(
        `https://api.hunter.io/v2/domain-search?domain=${domain}&api_key=2a3dd50641e08813a277e70eeae2d2f036d8e623`
      );
      const data = await response.json();
      
      if (data.data) {
        setMonthlyRequestCount(prev => prev + 1);
        
        // Try to find a matching person in the results
        const people = data.data.emails || [];
        const matchingPerson = people.find(person => 
          person.value.toLowerCase() === email.toLowerCase() ||
          (person.first_name && person.last_name && 
           `${person.first_name} ${person.last_name}`.toLowerCase() === name.toLowerCase())
        );

        if (matchingPerson && matchingPerson.linkedin) {
          return {
            linkedin: matchingPerson.linkedin,
            confidence: 'high',
            source: 'exact match'
          };
        }

        // If no exact match, try to find someone from the same company with a similar name
        const similarPerson = people.find(person =>
          person.linkedin &&
          person.first_name &&
          person.last_name &&
          name.toLowerCase().includes(person.first_name.toLowerCase())
        );

        if (similarPerson) {
          return {
            linkedin: similarPerson.linkedin,
            confidence: 'medium',
            source: 'similar name'
          };
        }
      }

      // If no results from domain search, construct a probable LinkedIn URL
      const formattedName = name.toLowerCase().replace(/[^a-z0-9]/g, '');
      const formattedCompany = company ? company.toLowerCase().replace(/[^a-z0-9]/g, '') : '';
      const probableUrl = `https://www.linkedin.com/in/${formattedName}${formattedCompany ? `-${formattedCompany}` : ''}`;
      
      return {
        linkedin: probableUrl,
        confidence: 'low',
        source: 'constructed'
      };

    } catch (error) {
      console.error('Error searching LinkedIn profile:', error);
      setNotification({
        open: true,
        message: 'Error searching LinkedIn profile',
        severity: 'error'
      });
      return null;
    } finally {
      setSearchLoading(prev => ({ ...prev, [email]: false }));
    }
  };

  // Search for single profile
  const findLinkedInProfile = async (email, name, company) => {
    if (linkedinData[email]) {
      window.open(linkedinData[email].linkedin, '_blank');
      return;
    }

    const result = await searchLinkedInProfile(email, name, company);
    if (result) {
      setLinkedinData(prev => ({
        ...prev,
        [email]: result
      }));
      window.open(result.linkedin, '_blank');
    }
  };

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: loginData.email,
        password: loginData.password,
      });

      if (error) throw error;

      setIsAuthenticated(true);
      await fetchSubmissions();
      
      setNotification({
        open: true,
        message: 'Successfully logged in!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error logging in:', error);
      setNotification({
        open: true,
        message: error.message || 'Invalid credentials',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      // First clear local state
      setIsAuthenticated(false);
      setSubmissions([]);
      setFilteredSubmissions([]);
      setCompanies([]);
      setLinkedinData({});
      setSearchLoading({});
      setMonthlyRequestCount(0);
      setLoginData({ email: '', password: '' });

      // Then attempt to sign out from Supabase
      await supabase.auth.signOut();
      
      setNotification({
        open: true,
        message: 'Successfully logged out',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error logging out:', error);
      // Even if there's an error with Supabase signout, we've already cleared local state
      setNotification({
        open: true,
        message: 'Logged out',
        severity: 'success'
      });
    }
  };

  // Check authentication status on mount
  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const isAuthed = !!session;
        setIsAuthenticated(isAuthed);
        
        if (isAuthed) {
          fetchSubmissions();
        }
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAuthenticated(false);
      }
    };
    
    checkSession();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      const isAuthed = !!session;
      setIsAuthenticated(isAuthed);
      
      if (isAuthed) {
        fetchSubmissions();
      } else {
        // Clear all data when logged out
        setSubmissions([]);
        setFilteredSubmissions([]);
        setCompanies([]);
        setLinkedinData({});
        setSearchLoading({});
        setMonthlyRequestCount(0);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  // Subscribe to real-time updates
  useEffect(() => {
    if (isAuthenticated) {
      const subscription = supabase
        .channel('tree_plantings')
        .on('postgres_changes', 
          { event: '*', schema: 'public', table: 'tree_plantings' },
          () => {
            fetchSubmissions();
          }
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [isAuthenticated]);

  // Update companies list when submissions change
  useEffect(() => {
    if (submissions.length > 0) {
      const uniqueCompanies = [...new Set(submissions.map(sub => sub.company))].filter(Boolean);
      setCompanies(uniqueCompanies.sort());
    }
  }, [submissions]);

  // Filter submissions based on search and company
  useEffect(() => {
    if (submissions.length > 0) {
      let filtered = [...submissions];
      
      // Apply search filter
      if (searchTerm) {
        filtered = filtered.filter(submission => 
          Object.values(submission).some(value => 
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
      
      // Apply company filter
      if (selectedCompany !== 'all') {
        filtered = filtered.filter(submission => submission.company === selectedCompany);
      }
      
      setFilteredSubmissions(filtered);
    } else {
      setFilteredSubmissions([]);
    }
  }, [submissions, searchTerm, selectedCompany]);

  return (
    <Container maxWidth={isAuthenticated ? 'lg' : 'sm'}>
      <Box
        component="div"
        sx={{
          minHeight: '100vh',
          py: 4,
        }}
      >
        {!isAuthenticated ? (
          <Paper
            elevation={1}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 2,
              border: '1px solid #e0e0e0',
            }}
          >
            <Box
              component="img"
              src={greenVantageLogo}
              alt="Green Vantage Logo"
              sx={{
                width: 180,
                mb: 3,
              }}
            />
            
            <Typography variant="h4" sx={{ 
              mb: 1, 
              fontWeight: 700, 
              textAlign: 'center',
              color: '#1e8449',
              letterSpacing: '-0.5px',
            }}>
              Admin Login
            </Typography>
            
            <Typography variant="body1" sx={{ 
              mb: 4,
              color: '#666666',
              textAlign: 'center',
            }}>
              Sign in to manage tree planting submissions
            </Typography>

            <Box 
              component="form" 
              onSubmit={handleLogin}
              sx={{
                width: '100%',
                maxWidth: 400,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                fullWidth
                type="email"
                label="Email"
                value={loginData.email}
                onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                disabled={isLoading}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#f8faf8',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1e8449',
                      borderWidth: '2px',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: '#1e8449' }} />
                    </InputAdornment>
                  ),
                }}
              />
              
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Password"
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                disabled={isLoading}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#f8faf8',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1e8449',
                      borderWidth: '2px',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: '#1e8449' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{
                  mt: 2,
                  py: 1.5,
                  backgroundColor: '#1e8449',
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '1rem',
                  boxShadow: '0 4px 12px rgba(30, 132, 73, 0.2)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: '#196f3d',
                    boxShadow: '0 6px 16px rgba(30, 132, 73, 0.3)',
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} sx={{ color: '#fff' }} />
                ) : (
                  'Sign In'
                )}
              </Button>
            </Box>
          </Paper>
        ) : (
          <>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 1 }}>
                <TextField
                  size="small"
                  placeholder="Search submissions..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: '#1e8449' }} />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: 2,
                    }
                  }}
                  sx={{
                    minWidth: 200,
                    '& .MuiOutlinedInput-root': {
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#f8faf8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1e8449',
                        borderWidth: '2px',
                      },
                    },
                  }}
                />
                
                <TextField
                  select
                  size="small"
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  sx={{
                    minWidth: 200,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#f8faf8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1e8449',
                        borderWidth: '2px',
                      },
                    },
                  }}
                >
                  <MenuItem value="all">All Companies</MenuItem>
                  {companies.map((company) => (
                    <MenuItem key={company} value={company}>{company}</MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {monthlyRequestCount > 0 && (
                  <Tooltip title={`${monthlyRequestCount}/${MONTHLY_LIMIT} searches used this month`}>
                    <Typography variant="body2" sx={{ color: '#666666' }}>
                      ({MONTHLY_LIMIT - monthlyRequestCount} searches left)
                    </Typography>
                  </Tooltip>
                )}
                
                <Button
                  variant="contained"
                  onClick={exportToExcel}
                  startIcon={<DownloadIcon />}
                  sx={{
                    backgroundColor: '#1e8449',
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: '0 4px 12px rgba(30, 132, 73, 0.2)',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#196f3d',
                      boxShadow: '0 6px 16px rgba(30, 132, 73, 0.3)',
                    },
                  }}
                >
                  Export to Excel
                </Button>

                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  sx={{
                    borderColor: '#1e8449',
                    color: '#1e8449',
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      borderColor: '#196f3d',
                      backgroundColor: 'rgba(30, 132, 73, 0.04)',
                    },
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>

            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#fff',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box>
                      <Typography variant="h4" sx={{ 
                        fontWeight: 700, 
                        color: '#1e8449', 
                        letterSpacing: '-0.5px',
                        mb: 0.5,
                      }}>
                        {companies.length}
                      </Typography>
                      <Typography variant="body1" sx={{ 
                        color: '#666666',
                        fontWeight: 500,
                      }}>
                        Unique Companies
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#fff',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box>
                      <Typography variant="h4" sx={{ 
                        fontWeight: 700, 
                        color: '#1e8449', 
                        letterSpacing: '-0.5px',
                        mb: 0.5,
                      }}>
                        {filteredSubmissions.length}
                      </Typography>
                      <Typography variant="body1" sx={{ 
                        color: '#666666',
                        fontWeight: 500,
                      }}>
                        {searchTerm || selectedCompany !== 'all' ? 'Filtered Trees' : 'Total Trees'}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            <TableContainer 
              component={Paper} 
              elevation={1}
              sx={{
                borderRadius: 1,
                border: '1px solid #e0e0e0',
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ 
                    backgroundColor: '#e9f7ef',
                  }}>
                    <TableCell sx={{ fontWeight: 600, color: '#1e8449', py: 2 }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#1e8449', py: 2 }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#1e8449', py: 2 }}>Company</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#1e8449', py: 2 }}>Message</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#1e8449', py: 2 }}>Submitted At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredSubmissions.length > 0 ? filteredSubmissions : submissions).map((submission) => (
                    <TableRow 
                      key={submission.id} 
                      hover
                      sx={{
                        transition: 'background-color 0.2s ease',
                        '&:hover': {
                          backgroundColor: '#f8faf8',
                        },
                      }}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {submission.name}
                          <Tooltip title={
                            linkedinData[submission.email]
                              ? `Confidence: ${linkedinData[submission.email].confidence} (${linkedinData[submission.email].source})`
                              : 'Search LinkedIn Profile'
                          }>
                            <IconButton 
                              size="small" 
                              onClick={() => findLinkedInProfile(submission.email, submission.name, submission.company)}
                              disabled={searchLoading[submission.email]}
                              sx={{
                                color: linkedinData[submission.email] ? '#0A66C2' : '#666666',
                                '&:hover': {
                                  color: '#0A66C2',
                                },
                              }}
                            >
                              {searchLoading[submission.email] ? (
                                <CircularProgress size={16} />
                              ) : (
                                <LinkedInIcon fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                          {monthlyRequestCount > 0 && linkedinData[submission.email] && (
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                color: linkedinData[submission.email].confidence === 'high' 
                                  ? '#1e8449'
                                  : linkedinData[submission.email].confidence === 'medium'
                                  ? '#f39c12'
                                  : '#666666',
                                fontStyle: 'italic'
                              }}
                            >
                              ({linkedinData[submission.email].confidence})
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{submission.email}</TableCell>
                      <TableCell>{submission.company}</TableCell>
                      <TableCell>{submission.message}</TableCell>
                      <TableCell>
                        {new Date(submission.created_at).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                  {(filteredSubmissions.length === 0 && submissions.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No submissions yet
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setNotification(prev => ({ ...prev, open: false }))}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InternalDashboard;
