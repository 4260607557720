import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';
import { 
  Box,
  Container,
  Typography,
  useTheme,
  Grid,
  Paper,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { motion } from 'framer-motion';

const About = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const leadership = [
    {
      name: 'Manoj Khimji',
      title: 'Managing Director, TMV Group',
      description: `Leading TMV Group's media, technology, and sports ventures. Established key partnerships with global media brands like Wall Street Journal, The Economist, and Reuters.`,
      location: 'Dubai, UAE'
    },
    {
      name: 'Robin Phillips',
      title: 'General Manager',
      description: `Leads our mission to integrate sustainability into marketing and media departments, focusing on carbon emission reduction and marketing activity offsetting.`,
      location: 'Dubai, UAE'
    }
  ];

  return (
    <>
      <SEO page="about" />
      <Helmet>
        <title>About Us | The GreenVantage - Sustainable Digital Marketing</title>
        <meta name="description" content="Learn about The GreenVantage's mission to revolutionize digital advertising through sustainable practices." />
      </Helmet>

      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          pt: { xs: 8, md: 10 },
          pb: { xs: 6, md: 8 },
          background: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.main}15 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: `radial-gradient(circle at 50% 50%, ${theme.palette.primary.main}10 0%, transparent 50%)`,
            animation: 'pulse 15s ease-in-out infinite',
          },
          '@keyframes pulse': {
            '0%': { opacity: 0.5 },
            '50%': { opacity: 0.8 },
            '100%': { opacity: 0.5 },
          }
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              variant="h1" 
              sx={{ 
                fontWeight: 800,
                fontSize: { xs: '2.5rem', md: '4rem' },
                textAlign: 'center',
                mb: { xs: 2, md: 3 },
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                letterSpacing: '-0.02em'
              }}
            >
              Transforming Digital Advertising
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'text.secondary',
                textAlign: 'center',
                mb: { xs: 4, md: 6 },
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6
              }}
            >
              Leading the sustainable revolution in digital marketing across the MENA region
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Box sx={{ bgcolor: 'background.paper', py: { xs: 6, md: 8 } }}>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Typography 
              variant="h3" 
              sx={{ 
                fontWeight: 700,
                mb: { xs: 3, md: 4 },
                textAlign: 'center',
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '60px',
                  height: '4px',
                  background: theme.palette.primary.main,
                  borderRadius: '2px'
                }
              }}
            >
              Who We Are
            </Typography>
            <Typography 
              sx={{ 
                color: 'text.secondary',
                textAlign: 'center',
                maxWidth: '800px',
                mx: 'auto',
                fontSize: '1.1rem',
                lineHeight: 1.8,
                mt: 3
              }}
            >
              The Vantage is a dynamic team of 30 professionals strategically positioned across UAE, Saudi Arabia, and Qatar. 
              As part of the TMV Group, we combine expertise in media, technology, and sustainability to drive innovation 
              in digital advertising throughout the MENA region.
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Box 
        sx={{ 
          bgcolor: 'grey.50',
          py: { xs: 6, md: 8 },
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}05 0%, transparent 100%)`,
          }
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: 700,
              mb: { xs: 4, md: 6 },
              textAlign: 'center',
              position: 'relative'
            }}
          >
            Our Leadership
          </Typography>
          <Grid container spacing={4}>
            {leadership.map((leader, index) => (
              <Grid item xs={12} md={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper 
                    sx={{ 
                      p: { xs: 3, md: 4 },
                      height: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        '& .leader-gradient': {
                          opacity: 0.1
                        }
                      }
                    }}
                  >
                    <Box 
                      className="leader-gradient"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                        opacity: 0.05,
                        transition: 'opacity 0.3s ease-in-out'
                      }}
                    />
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Typography 
                        variant="h4" 
                        sx={{ 
                          fontWeight: 700,
                          mb: 1,
                          background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                          backgroundClip: 'text',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        }}
                      >
                        {leader.name}
                      </Typography>
                      <Typography 
                        variant="subtitle1" 
                        color="primary.main" 
                        sx={{ 
                          mb: 2,
                          fontWeight: 500,
                          fontSize: '1.1rem'
                        }}
                      >
                        {leader.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          mb: 3,
                          color: 'text.secondary',
                          lineHeight: 1.8
                        }}
                      >
                        {leader.description}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocationOnIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="body2" color="text.secondary">
                          {leader.location}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 8 } }}>
        <Typography 
          variant="h3" 
          sx={{ 
            fontWeight: 700,
            mb: { xs: 4, md: 6 },
            textAlign: 'center'
          }}
        >
          Our Values
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <Paper 
                sx={{ 
                  p: { xs: 3, md: 4 },
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    '& .value-gradient': {
                      opacity: 0.1
                    }
                  }
                }}
              >
                <Box 
                  className="value-gradient"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                    opacity: 0.05,
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                />
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 700,
                      mb: 2,
                      color: theme.palette.primary.main
                    }}
                  >
                    Sustainability First
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', lineHeight: 1.8 }}>
                    Creating positive environmental impact through innovative digital solutions.
                  </Typography>
                </Box>
              </Paper>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <Paper 
                sx={{ 
                  p: { xs: 3, md: 4 },
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    '& .value-gradient': {
                      opacity: 0.1
                    }
                  }
                }}
              >
                <Box 
                  className="value-gradient"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                    opacity: 0.05,
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                />
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 700,
                      mb: 2,
                      color: theme.palette.primary.main
                    }}
                  >
                    Data-Driven Results
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', lineHeight: 1.8 }}>
                    Strategies backed by comprehensive analytics and real-time optimization.
                  </Typography>
                </Box>
              </Paper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;