import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
  useTheme,
  IconButton,
  Snackbar,
  Stack,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Helmet } from 'react-helmet-async';
import { sendContactFormEmail } from '../services/emailService';
import SEO from '../components/SEO';

const Contact = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    company: ''
  });
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // List of common personal email domains
  const personalEmailDomains = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com',
    'icloud.com', 'mail.com', 'protonmail.com', 'zoho.com', 'yandex.com',
    'live.com', 'inbox.com', 'gmx.com', 'fastmail.com'
  ];

  const validateEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }

    // Check if it's a personal email domain
    const domain = email.split('@')[1].toLowerCase();
    if (personalEmailDomains.includes(domain)) {
      return 'Please use your business email address';
    }

    return '';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }

    // Validate email as user types
    if (name === 'email' && value) {
      const emailError = validateEmail(value);
      if (emailError) {
        setErrors(prev => ({
          ...prev,
          email: emailError
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else {
      const emailError = validateEmail(formData.email);
      if (emailError) {
        newErrors.email = emailError;
      }
    }
    if (!formData.company.trim()) {
      newErrors.company = 'Company name is required';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setSnackbar({
        open: true,
        message: 'Please correct the errors before submitting',
        severity: 'error'
      });
      return;
    }

    setLoading(true);

    try {
      const result = await sendContactFormEmail(formData);

      if (!result.success) {
        throw new Error(result.error || 'Failed to send message');
      }

      setSnackbar({
        open: true,
        message: 'Thank you for your message. We will get back to you soon!',
        severity: 'success',
      });

      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        company: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: 'There was an error sending your message. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const contactInfo = [
    {
      icon: <LocationOnIcon sx={{ fontSize: 40 }} />,
      title: 'Visit Us',
      content: 'Reef Tower - 8th Floor - Cluster O - Jumeirah Lake Towers - Dubai, UAE',
    },
    {
      icon: <PhoneIcon sx={{ fontSize: 40 }} />,
      title: 'Call Us',
      content: '04 425 3300',
    },
    {
      icon: <EmailIcon sx={{ fontSize: 40 }} />,
      title: 'Email Us',
      content: 'info@themediavantage.com',
    },
  ];

  return (
    <>
      <SEO page="contact" />
      <Box>
        {/* Hero Section */}
        <Box
          sx={{
            background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
            color: 'white',
            py: 8,
            position: 'relative',
            overflow: 'hidden',
            mt: 0
          }}
        >
          <Container maxWidth="lg">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h2"
                textAlign="center"
                sx={{ fontWeight: 700, mb: 2 }}
              >
                Get in Touch
              </Typography>
              <Typography
                variant="h5"
                textAlign="center"
                sx={{ opacity: 0.9, maxWidth: 600, mx: 'auto' }}
              >
                We'd love to hear from you. Let's transform your advertising strategy together.
              </Typography>
            </motion.div>
          </Container>
        </Box>

        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Grid container spacing={6}>
            {/* Contact Information */}
            <Grid item xs={12} md={4}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h4" sx={{ mb: 4, fontWeight: 700 }}>
                  Contact Information
                </Typography>
                <Stack spacing={4}>
                  {contactInfo.map((info, index) => (
                    <motion.div
                      key={info.title}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box
                          sx={{
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'flex-start',
                          }}
                        >
                          {info.icon}
                        </Box>
                        <Box>
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            {info.title}
                          </Typography>
                          <Typography color="text.secondary">
                            {info.content}
                          </Typography>
                        </Box>
                      </Box>
                    </motion.div>
                  ))}
                </Stack>
              </Box>

              {/* Social Media Links */}
              <Box sx={{ mt: 6 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Connect With Us
                </Typography>
                <IconButton 
                  color="primary" 
                  href="https://www.linkedin.com/company/the-greenvantage/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(10, 102, 194, 0.08)'
                    }
                  }}
                >
                  <LinkedInIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>
            </Grid>

            {/* Contact Form */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                  Send us a Message
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Company"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      error={!!errors.company}
                      helperText={errors.company}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Business Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email || 'Please use your business email address'}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleInputChange}
                      error={!!errors.message}
                      helperText={errors.message}
                      required
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Send Message'}
                    </Button>
                  </Stack>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        {/* Success Message Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Helmet>
          <title>Contact Us | GreenVantage - Digital Advertising Solutions</title>
          <meta name="description" content="Get in touch with GreenVantage. We're here to help you with all your digital advertising needs while making a positive impact on the environment." />
          <meta property="og:title" content="Contact GreenVantage - Digital Advertising Solutions" />
          <meta property="og:description" content="Reach out to GreenVantage for innovative digital advertising solutions that make a difference." />
        </Helmet>
      </Box>
    </>
  );
};

export default Contact;