import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SEO from '../components/SEO';

const CarbonCalculator = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    adCampaign: {
      impressionsPerDay: '',
      budget: '',
      cpm: '',
      campaignDays: 30,
      adFormat: 'display',
      averageAdSizeKb: 150,
    }
  });
  
  const [carbonFootprint, setCarbonFootprint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // Animation variants for interactive elements
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  // Emission factors (simplified for campaign planners)
  const EMISSION_FACTORS = {
    display: 0.81,  // kg CO2e per million impressions
    video: 2.95,    // Video ads have higher impact
    rich: 1.52      // Rich media ads
  };

  const calculateImpressions = useCallback(() => {
    if (formData.adCampaign.budget && formData.adCampaign.cpm) {
      // Daily budget is used to calculate daily impressions
      // CPM is cost per 1000 impressions, so multiply by 1000 to get cost per impression
      const dailyImpressions = (formData.adCampaign.budget / (formData.adCampaign.cpm / 1000));
      handleInputChange('adCampaign', 'impressionsPerDay', Math.round(dailyImpressions));
    }
  }, [formData.adCampaign.budget, formData.adCampaign.cpm]);

  useEffect(() => {
    calculateImpressions();
  }, [calculateImpressions]);

  const calculateCarbonFootprint = () => {
    setLoading(true);
    setShowSuccess(false);

    // Total impressions for the entire campaign
    const totalImpressions = formData.adCampaign.impressionsPerDay * formData.adCampaign.campaignDays;
    const totalEmissions = (totalImpressions / 1000000) * EMISSION_FACTORS[formData.adCampaign.adFormat];

    // Simulate API call with setTimeout
    setTimeout(() => {
      setCarbonFootprint({
        total: totalEmissions,
        perMille: (totalEmissions / totalImpressions) * 1000,
        recommendations: getRecommendations(totalEmissions)
      });
      setLoading(false);
      setShowSuccess(true);
      setActiveStep((prevStep) => prevStep + 1);
    }, 2000);
  };

  const getRecommendations = (emissions) => {
    const highImpact = emissions > 100;
    const mediumImpact = emissions > 50;
    
    const recommendations = [];
    
    if (highImpact) {
      recommendations.push({
        severity: 'error',
        title: 'High Environmental Impact',
        actions: [
          'Consider reducing campaign duration or daily impressions',
          'Switch to lighter ad formats',
          'Target regions with greener energy grids',
          'Learn about our sustainability initiatives'
        ]
      });
    } else if (mediumImpact) {
      recommendations.push({
        severity: 'warning',
        title: 'Medium Environmental Impact',
        actions: [
          'Optimize ad creative file sizes',
          'Consider using The GoodNet for better efficiency',
          'Implement frequency capping to reduce unnecessary impressions'
        ]
      });
    } else {
      recommendations.push({
        severity: 'success',
        title: 'Low Environmental Impact',
        actions: [
          'Great job! Your campaign is relatively eco-friendly',
          'Consider offsetting remaining emissions through our tree planting program'
        ]
      });
    }
    
    return recommendations;
  };

  const handleInputChange = (category, field, value) => {
    setFormData(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: value
      }
    }));
  };

  const steps = ['Campaign Details', 'Calculate Impact', 'Review & Optimize'];

  const handleNext = () => {
    if (activeStep === 1) {
      calculateCarbonFootprint();
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getImpactLevel = (emissions) => {
    if (emissions > 100) return { level: 'High', color: '#d32f2f', icon: <ErrorOutlineIcon /> };
    if (emissions > 50) return { level: 'Medium', color: '#ed6c02', icon: <WarningAmberIcon /> };
    return { level: 'Low', color: '#2e7d32', icon: <ThumbUpIcon /> };
  };

  const getEnvironmentalEquivalent = (emissions) => {
    const trees = Math.round(emissions * 0.5); // Trees needed to offset
    const carMiles = Math.round(emissions * 2.4); // Equivalent car miles
    const phoneCharges = Math.round(emissions * 121.6); // Equivalent phone charges

    return {
      trees,
      carMiles,
      phoneCharges
    };
  };

  return (
    <>
      <SEO page="calculator" />
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Typography variant="h2" component="h1" gutterBottom align="center" 
            sx={{ 
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4,
              fontSize: { xs: '2.5rem', md: '3.5rem' }
            }}>
            Carbon Impact Calculator
          </Typography>
          <motion.div variants={itemVariants}>
            <Typography variant="h5" component="h2" gutterBottom align="center" 
              sx={{ 
                color: theme.palette.text.secondary,
                mb: 6,
                maxWidth: '800px',
                mx: 'auto'
              }}>
              Make your advertising campaigns sustainable and measure their environmental footprint
            </Typography>
          </motion.div>

          <Stepper activeStep={activeStep} sx={{ mb: 6 }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <motion.div
                    animate={activeStep >= index ? { scale: [1, 1.2, 1] } : {}}
                    transition={{ duration: 0.5 }}
                  >
                    {label}
                  </motion.div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              <Card 
                elevation={3}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  background: 'linear-gradient(to bottom right, #ffffff, #f8f9fa)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Daily Budget (USD)"
                      type="number"
                      value={formData.adCampaign.budget}
                      onChange={(e) => handleInputChange('adCampaign', 'budget', e.target.value)}
                      InputProps={{
                        startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                      }}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      label="CPM (Cost per 1000 impressions in USD)"
                      type="number"
                      value={formData.adCampaign.cpm}
                      onChange={(e) => handleInputChange('adCampaign', 'cpm', e.target.value)}
                      InputProps={{
                        startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel>Ad Format</InputLabel>
                      <Select
                        value={formData.adCampaign.adFormat}
                        label="Ad Format"
                        onChange={(e) => handleInputChange('adCampaign', 'adFormat', e.target.value)}
                      >
                        <MenuItem value="display">Display Ads</MenuItem>
                        <MenuItem value="video">Video Ads</MenuItem>
                        <MenuItem value="rich">Rich Media</MenuItem>
                      </Select>
                    </FormControl>
                    <Alert severity="info" sx={{ mb: 3 }}>
                      Estimated Daily Impressions: {formData.adCampaign.impressionsPerDay.toLocaleString()}
                    </Alert>
                    <Typography variant="body2" color="text.secondary">
                      Enter your campaign budget and CPM, and we'll help you understand the environmental impact
                      of your campaign while suggesting optimizations for sustainability.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </motion.div>
          )}

          {activeStep === 1 && (
            <Box sx={{ 
              textAlign: 'center',
              minHeight: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {loading ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CircularProgress size={60} thickness={4} />
                  <Typography variant="h6" sx={{ mt: 3 }}>
                    Calculating Environmental Impact...
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Analyzing your campaign data for sustainable insights
                  </Typography>
                </motion.div>
              ) : (
                <>
                  <Typography variant="h4" gutterBottom>
                    Ready to calculate your campaign's impact?
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCarbonFootprint}
                    size="large"
                    sx={{ mt: 2 }}
                  >
                    Calculate Now
                  </Button>
                </>
              )}
            </Box>
          )}

          {activeStep === 2 && carbonFootprint && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              {showSuccess && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <Alert 
                    severity="success" 
                    sx={{ mb: 3 }}
                  >
                    Calculation completed successfully! Here's your campaign's environmental impact.
                  </Alert>
                </motion.div>
              )}
              <Card 
                elevation={3}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  background: 'linear-gradient(to bottom right, #ffffff, #f8f9fa)',
                  mb: 4
                }}>
                <CardContent>
                  <Typography variant="h4" gutterBottom align="center" color="primary">
                    Campaign Impact Results
                  </Typography>
                  
                  {/* Impact Level Indicator */}
                  {(() => {
                    const impact = getImpactLevel(carbonFootprint.total);
                    return (
                      <Box sx={{ 
                        textAlign: 'center', 
                        my: 4,
                        p: 3,
                        borderRadius: 2,
                        bgcolor: `${impact.color}15`
                      }}>
                        <motion.div
                          animate={{ scale: [1, 1.1, 1] }}
                          transition={{ duration: 1, repeat: Infinity, repeatDelay: 2 }}
                        >
                          {impact.icon}
                        </motion.div>
                        <Typography variant="h5" sx={{ color: impact.color, mt: 2 }}>
                          {impact.level} Environmental Impact
                        </Typography>
                        <Typography variant="h3" sx={{ color: impact.color, my: 2 }}>
                          {carbonFootprint.total.toFixed(2)} kg CO₂e
                        </Typography>
                        <LinearProgress 
                          variant="determinate" 
                          value={Math.min((carbonFootprint.total / 150) * 100, 100)}
                          sx={{ 
                            height: 10, 
                            borderRadius: 5,
                            bgcolor: `${impact.color}30`,
                            '& .MuiLinearProgress-bar': {
                              bgcolor: impact.color
                            }
                          }}
                        />
                      </Box>
                    );
                  })()}

                  {/* Environmental Equivalents */}
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      Environmental Impact Equivalents
                    </Typography>
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                      {(() => {
                        const equivalents = getEnvironmentalEquivalent(carbonFootprint.total);
                        return (
                          <>
                            <Grid item xs={12} md={4}>
                              <Card elevation={2} sx={{ p: 2, height: '100%' }}>
                                <Box sx={{ textAlign: 'center' }}>
                                  <EmojiNatureIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                                  <Typography variant="h6">{equivalents.trees}</Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    Trees needed for annual offset
                                  </Typography>
                                </Box>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Card elevation={2} sx={{ p: 2, height: '100%' }}>
                                <Box sx={{ textAlign: 'center' }}>
                                  <LocalFloristIcon sx={{ fontSize: 40, color: 'secondary.main' }} />
                                  <Typography variant="h6">{equivalents.carMiles}</Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    Miles driven by an average car
                                  </Typography>
                                </Box>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Card elevation={2} sx={{ p: 2, height: '100%' }}>
                                <Box sx={{ textAlign: 'center' }}>
                                  <ThumbUpIcon sx={{ fontSize: 40, color: 'success.main' }} />
                                  <Typography variant="h6">{equivalents.phoneCharges}</Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    Smartphone charges
                                  </Typography>
                                </Box>
                              </Card>
                            </Grid>
                          </>
                        );
                      })()}
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 6 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      component={RouterLink}
                      to="/contact"
                      sx={{ 
                        py: 2,
                        background: 'linear-gradient(45deg, #2196f3, #1976d2)',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #1976d2, #1565c0)',
                          transform: 'scale(1.02)',
                          transition: 'all 0.3s ease-in-out'
                        }
                      }}
                    >
                      Take Action: Offset Your Impact Now
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          )}

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            {activeStep > 0 && (
              <Button
                variant="outlined"
                onClick={handleBack}
                sx={{ px: 4 }}
              >
                Back
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button
                variant="contained"
                onClick={handleNext}
                endIcon={activeStep === 1 ? <EmojiNatureIcon /> : <ThumbUpIcon />}
                disabled={loading || !formData.adCampaign.budget || !formData.adCampaign.cpm}
                sx={{ px: 4 }}
              >
                {activeStep === 1 ? 'Calculate Impact' : 'Next'}
              </Button>
            )}
          </Box>
        </motion.div>
      </Container>
    </>
  );
};

export default CarbonCalculator;
