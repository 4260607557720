import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#01a967', // Custom Green
      light: '#4CAF50', // Green 500
      dark: '#1B5E20', // Green 900
    },
    secondary: {
      main: '#1976D2', // Blue 700
      light: '#42A5F5', // Blue 400
      dark: '#1565C0', // Blue 800
    },
    success: {
      main: '#2E7D32', // Green 800
      light: '#4CAF50', // Green 500
      dark: '#1B5E20', // Green 900
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});
