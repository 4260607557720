import axios from 'axios';
import { supabase } from '../lib/supabaseClient';

const API_KEY = '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';
const BREVO_API_URL = 'https://api.brevo.com/v3/smtp/email';

export const sendTreePlantingEmail = async (userData) => {
  const { name, email, message, company } = userData;

  // First, save to Supabase
  let emailError = null;
  let emailSent = false;
  let submissionData = null;

  try {
    const { data, error } = await supabase
      .from('tree_plantings')
      .insert([
        {
          name,
          email,
          company,
          message,
          email_sent: false
        }
      ])
      .select()
      .single();

    if (error) throw new Error('Failed to save submission to database');
    submissionData = data;

    const emailData = {
      sender: {
        name: "The Vantage",
        email: "info@themediavantage.com"
      },
      to: [{
        email: email,
        name: name
      }],
      bcc: [{
        email: "amer.sarhan@thetechvantage.com",
        name: "Amer Sarhan"
      }],
      subject: "Thank you for planting a tree with The GreenVantage! - Campaign Middle East Awards 2024",
      htmlContent: `
        <html>
          <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px; background-color: #f9f9f9;">
            <div style="background-color: white; border-radius: 16px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);">
              <!-- Header with gradient -->
              <div style="background: white; padding: 30px 20px; text-align: center;">
                <img src="https://www.thegreenvantage.com/static/media/TGVbrandmark.4f491f3f15fbd5a3cec3.png" alt="GreenVantage Logo" style="width: 100px; height: auto; margin-bottom: 20px;"/>
                <h1 style="color: #2E7D32; margin: 0; font-size: 24px; font-weight: 500;">Your Tree is Being Planted! 🌱</h1>
              </div>

              <!-- Content -->
              <div style="padding: 30px 40px;">
                <h2 style="color: #2E7D32; margin: 0 0 20px 0; font-size: 20px;">Thank you for your contribution, ${name || 'Valued Contributor'}!</h2>
                
                <p style="font-size: 16px; color: #555; margin-bottom: 25px;">
                  We're excited to confirm that your tree planting request for the <strong>Campaign Middle East Awards 2024</strong> has been received.
                </p>

                <!-- Details Card -->
                <div style="background: #f8faf8; border-left: 4px solid #2E7D32; padding: 20px; margin: 25px 0; border-radius: 0 8px 8px 0;">
                  <h3 style="color: #2E7D32; margin: 0 0 15px 0; font-size: 16px; text-transform: uppercase; letter-spacing: 1px;">Your Details</h3>
                  <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                      <td style="padding: 8px 0; color: #666; width: 100px;">Name:</td>
                      <td style="padding: 8px 0; color: #333; font-weight: 500;">${name || 'Not provided'}</td>
                    </tr>
                    <tr>
                      <td style="padding: 8px 0; color: #666;">Company:</td>
                      <td style="padding: 8px 0; color: #333; font-weight: 500;">${company || 'Not provided'}</td>
                    </tr>
                    ${message ? `
                    <tr>
                      <td style="padding: 8px 0; color: #666;">Message:</td>
                      <td style="padding: 8px 0; color: #333; font-weight: 500;">${message}</td>
                    </tr>
                    ` : ''}
                  </table>
                </div>

                <!-- Impact Message -->
                <div style="text-align: center; margin: 30px 0; padding: 20px; background: linear-gradient(135deg, rgba(46, 125, 50, 0.1) 0%, rgba(76, 175, 80, 0.1) 100%); border-radius: 12px;">
                  <p style="color: #2E7D32; font-size: 18px; font-weight: 500; margin: 0;">
                    🌳 Your contribution helps make our planet greener! 🌍
                  </p>
                </div>

                <!-- Footer -->
                <div style="text-align: center; margin-top: 30px; padding-top: 30px; border-top: 1px solid #eee;">
                  <p style="margin: 0;">Best regards,</p>
                  <p style="margin: 5px 0; font-weight: bold;">The GreenVantage Team</p>
                </div>
              </div>
            </div>
          </body>
        </html>
      `
    };

    const response = await axios.post(BREVO_API_URL, emailData, {
      headers: {
        'api-key': API_KEY,
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 201) {
      emailSent = true;
      // Update the email_sent status in Supabase
      await supabase
        .from('tree_plantings')
        .update({ email_sent: true })
        .eq('id', submissionData.id);
    }
  } catch (error) {
    console.error('Error in tree planting process:', error);
    emailError = error;
    throw error;
  }

  return {
    success: emailSent && submissionData !== null,
    error: emailError,
    data: submissionData
  };
};

export const sendContactFormEmail = async (formData) => {
  const { name, email, subject, message } = formData;

  try {
    // Save to Supabase
    const { data, error } = await supabase
      .from('contacts')
      .insert({
        name,
        email,
        subject,
        message
      });

    if (error) {
      console.error('Supabase error:', error);
      throw new Error(`Failed to save contact submission: ${error.message}`);
    }

    // Send notification email to admin
    const emailData = {
      sender: {
        name: "MediaVantage Website",
        email: "amer.sarhan@thetechvantage.com"
      },
      to: [{
        email: "amer.sarhan@thetechvantage.com",
        name: "Amer Sarhan"
      }],
      subject: `New Contact Form Submission: ${subject}`,
      htmlContent: `
        <html>
          <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
            <h2>New Contact Form Submission</h2>
            <p><strong>Name:</strong> ${name}</p>
            <p><strong>Email:</strong> ${email}</p>
            <p><strong>Subject:</strong> ${subject}</p>
            <h3>Message:</h3>
            <p>${message}</p>
          </body>
        </html>
      `
    };

    // Send email
    await axios.post(BREVO_API_URL, emailData, {
      headers: {
        'api-key': API_KEY,
        'content-type': 'application/json',
      },
    });

    return { success: true, data };
  } catch (error) {
    console.error('Error sending contact form emails:', error);
    return { success: false, error: error.message };
  }
};

export const checkExistingSubmission = async (email) => {
  if (!email) return { exists: false };

  try {
    const { data, error } = await supabase
      .from('tree_plantings')
      .select('created_at')
      .eq('email', email.toLowerCase().trim())
      .order('created_at', { ascending: false })
      .limit(1);

    if (error) {
      console.error('Error checking submission:', error);
      return { exists: false };
    }

    return data && data.length > 0 ? {
      exists: true,
      submissionDate: new Date(data[0].created_at).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    } : { exists: false };
  } catch (error) {
    console.error('Error checking submission:', error);
    return { exists: false };
  }
};

export const getEmailStats = async () => {
  try {
    // Get both Brevo stats and database stats
    const [brevoResponse, { data: dbData, error: dbError }] = await Promise.all([
      axios.get('https://api.brevo.com/v3/smtp/statistics/events', {
        headers: {
          'accept': 'application/json',
          'api-key': API_KEY
        }
      }),
      supabase
        .from('tree_plantings')
        .select('*')
        .order('created_at', { ascending: false })
    ]);

    if (dbError) throw new Error('Failed to fetch database statistics');

    return {
      brevoStats: brevoResponse.data,
      submissions: dbData,
      totalSubmissions: dbData.length,
      successfulEmails: dbData.filter(record => record.email_sent).length
    };
  } catch (error) {
    console.error('Error getting stats:', error.response?.data || error.message);
    throw new Error('Failed to fetch statistics');
  }
};
