import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://xlnvznqcqmldcciimfmm.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhsbnZ6bnFjcW1sZGNjaWltZm1tIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE1NjQ2NjUsImV4cCI6MjA0NzE0MDY2NX0.xOZ7ZR9mCqnfNY2VAxye0JrKzZ3XME7ldokrjdJ4BJQ';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  realtime: true,
  db: {
    schema: 'public'
  }
}); 