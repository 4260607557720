import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import CampaignOutlined from '@mui/icons-material/CampaignOutlined';
import NetworkCheckOutlined from '@mui/icons-material/NetworkCheckOutlined';
import SchoolIcon from '@mui/icons-material/School';
import ForestIcon from '@mui/icons-material/Forest';
import SEO from '../components/SEO';

// Import partner logos
import bloombergLogo from '../assets/partners/bloomberg.png';
import economistLogo from '../assets/partners/economist.png';
import foxLogo from '../assets/partners/fox.png';
import reutersLogo from '../assets/partners/reuters.png';
import tedLogo from '../assets/partners/ted.png';
import wsjLogo from '../assets/partners/wsj.png';

const partners = [
  {
    name: 'Bloomberg',
    logo: bloombergLogo,
    maxWidth: { xs: '60px', sm: '80px' }
  },
  {
    name: 'Wall Street Journal',
    logo: wsjLogo,
    maxWidth: { xs: '65px', sm: '85px' }
  },
  {
    name: 'The Economist',
    logo: economistLogo,
    maxWidth: { xs: '60px', sm: '80px' }
  },
  {
    name: 'Reuters',
    logo: reutersLogo,
    maxWidth: { xs: '75px', sm: '100px' }
  },
  {
    name: 'Fox News',
    logo: foxLogo,
    maxWidth: { xs: '65px', sm: '85px' }
  },
  {
    name: 'TED',
    logo: tedLogo,
    maxWidth: { xs: '50px', sm: '70px' }
  }
];

const solutions = [
  {
    id: 'education',
    title: 'Education',
    description: 'Join our Climate Fresk workshops to understand climate change impacts and solutions in the advertising industry.',
    icon: <SchoolIcon />,
    color: '#E67E22',
    gradient: 'linear-gradient(135deg, #E67E22 0%, #D35400 100%)',
  },
  {
    id: 'greennetwork',
    title: 'GreenNetwork',
    description: 'Access our curated network of premium publishers and The GoodNet for sustainable digital advertising.',
    icon: <NetworkCheckOutlined />,
    color: '#0FA66C',
    gradient: 'linear-gradient(135deg, #0FA66C 0%, #2ECC71 100%)',
  },
  {
    id: 'goodiq',
    title: 'GoodIQ',
    description: 'Plan and measure campaigns aligned with your ESG and advertising performance goals using our intelligent platform.',
    icon: <CampaignOutlined />,
    color: '#3498DB',
    gradient: 'linear-gradient(135deg, #3498DB 0%, #2980B9 100%)',
  },
  {
    id: 'offsetting',
    title: 'Carbon Offsetting',
    description: 'Offset your campaign emissions through our partnership with Treenation, supporting global reforestation projects.',
    icon: <ForestIcon />,
    color: '#27AE60',
    gradient: 'linear-gradient(135deg, #27AE60 0%, #219A52 100%)',
  }
];

const SolutionCard = ({ solution }) => {
  const isGreenNetwork = solution.id === 'greennetwork';

  return (
    <Box
      sx={{
        perspective: '2000px',
        height: { xs: 400, sm: 350 },
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          transition: 'transform 0.8s',
          transformStyle: 'preserve-3d',
          cursor: isGreenNetwork ? 'pointer' : 'default',
          '&:hover': isGreenNetwork ? {
            transform: 'rotateY(180deg)',
          } : {},
        }}
      >
        {/* Front */}
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            p: { xs: 3, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 2,
            background: '#fff',
            transition: 'all 0.3s ease',
            ...(isGreenNetwork ? {
              '&::after': {
                content: '"Hover to see our partners"',
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                fontSize: '0.75rem',
                color: 'text.secondary',
                opacity: 0.7,
              }
            } : {}),
          }}
        >
          <Box
            className="solution-icon"
            sx={{
              mb: { xs: 2, md: 3 },
              width: { xs: 56, md: 64 },
              height: { xs: 56, md: 64 },
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: `linear-gradient(135deg, ${solution.color}15, ${solution.color}25)`,
              transition: 'all 0.3s ease',
              '& svg': {
                fontSize: { xs: '1.75rem', md: '2rem' },
                color: solution.color,
              }
            }}
          >
            {solution.icon}
          </Box>

          <Typography 
            variant="h5" 
            component="h3" 
            sx={{ 
              mb: { xs: 1, md: 2 },
              fontWeight: 600,
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              color: 'text.primary'
            }}
          >
            {solution.title}
          </Typography>
          <Typography 
            color="text.secondary"
            sx={{
              fontSize: { xs: '0.875rem', md: '1rem' },
              lineHeight: 1.6
            }}
          >
            {solution.description}
          </Typography>
        </Paper>

        {/* Back (Only for GreenNetwork+) */}
        {isGreenNetwork && (
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backfaceVisibility: 'hidden',
              transform: 'rotateY(180deg)',
              p: { xs: 2, md: 3 },
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: '#fff',
              border: `2px solid ${solution.color}`,
              overflow: 'hidden'
            }}
          >
            <Box sx={{ mb: { xs: 1.5, sm: 2 } }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  fontWeight: 700,
                  color: '#0FA66C',
                  textAlign: 'center',
                  mb: 0.5,
                  fontSize: { xs: '1.25rem', sm: '1.5rem' }
                }}
              >
                The GoodNet
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  textAlign: 'center',
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                Our sustainable advertising network
              </Typography>
            </Box>

            <Typography
              variant="h6"
              component="h3"
              sx={{
                mb: { xs: 2, sm: 3 },
                fontWeight: 600,
                color: 'text.primary',
                textAlign: 'center',
                fontSize: { xs: '1.1rem', sm: '1.25rem' }
              }}
            >
              Our Premium Partners
            </Typography>
            
            <Grid 
              container 
              spacing={{ xs: 2, sm: 3 }}
              sx={{ 
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                px: { xs: 1, sm: 2 }
              }}
            >
              {partners.map((partner) => (
                <Grid 
                  item 
                  xs={4}
                  key={partner.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Box
                    component="img"
                    src={partner.logo}
                    alt={`${partner.name} logo`}
                    sx={{
                      width: '100%',
                      maxWidth: partner.maxWidth,
                      height: 'auto',
                      filter: 'grayscale(100%)',
                      opacity: 0.7,
                      transition: 'all 0.3s ease',
                      objectFit: 'contain',
                      '&:hover': {
                        opacity: 0.9,
                        transform: 'scale(1.05)',
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO page="solutions" />
      <Helmet>
        <title>Solutions - Sustainable Digital Advertising | GreenVantage</title>
        <meta 
          name="description" 
          content="Discover our comprehensive suite of sustainable digital advertising solutions. From green infrastructure to smart campaign optimization." 
        />
      </Helmet>

      <Box 
        sx={{ 
          minHeight: '100vh',
          background: 'linear-gradient(180deg, rgba(15, 166, 108, 0.05) 0%, rgba(255, 255, 255, 0) 100%)',
          pt: { xs: 4, md: 8 },
          pb: { xs: 8, md: 12 }
        }}
      >
        <Container maxWidth="lg">
          {/* Header Section */}
          <Box sx={{ mb: { xs: 6, md: 10 } }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h1"
                align="center"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
                  mb: { xs: 2, md: 3 },
                  background: 'linear-gradient(45deg, #0FA66C, #2ECC71)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  lineHeight: 1.2
                }}
              >
                Sustainable Solutions for
                Digital Advertising
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <Typography
                variant="h5"
                align="center"
                sx={{
                  color: 'text.secondary',
                  maxWidth: '800px',
                  mx: 'auto',
                  fontSize: { xs: '1rem', sm: '1.25rem' },
                  lineHeight: 1.6,
                  px: { xs: 2, sm: 0 }
                }}
              >
                Transform your digital advertising with our comprehensive suite of eco-friendly solutions.
                Maximize performance while minimizing environmental impact.
              </Typography>
            </motion.div>
          </Box>

          {/* Solutions Grid */}
          <Grid container spacing={{ xs: 3, md: 4 }}>
            {solutions.map((solution, index) => (
              <Grid item xs={12} md={6} key={solution.id}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  style={{ height: '100%' }}
                >
                  <SolutionCard solution={solution} />
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Solutions;