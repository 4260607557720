import React, { useState } from 'react';
import { 
  AppBar, 
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CalculateIcon from '@mui/icons-material/Calculate';
import NatureIcon from '@mui/icons-material/Nature';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/TGVlogo.png';
import { motion, AnimatePresence } from 'framer-motion';

const PARTICLE_SHAPES = [
  '🌱', '🌿', '🌲', '🍃', '♻️', '💚', '🌍', '🌎', '🌏', '☘️', '🪴', '🌳', '🌺', '🌸', '🪷'
];

const Particles = ({ isActive }) => {
  const particles = Array.from({ length: 12 }).map((_, i) => ({
    x: Math.random() * 100 - 50,
    y: Math.random() * -70 - 20,
    rotation: Math.random() * 360,
    scale: Math.random() * 0.7 + 0.5,
    delay: i * 0.04,
    shape: PARTICLE_SHAPES[Math.floor(Math.random() * PARTICLE_SHAPES.length)]
  }));

  return (
    <AnimatePresence>
      {isActive && (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', pointerEvents: 'none' }}>
          {particles.map((particle, i) => (
            <motion.div
              key={i}
              initial={{ 
                x: 0, 
                y: 0, 
                scale: 0,
                rotate: 0,
                opacity: 0 
              }}
              animate={{ 
                x: particle.x,
                y: particle.y,
                scale: particle.scale,
                rotate: particle.rotation,
                opacity: [0, 1, 0]
              }}
              transition={{ 
                duration: 1,
                delay: particle.delay,
                ease: "easeOut"
              }}
              style={{
                position: 'absolute',
                fontSize: '16px',
                lineHeight: 1,
                transform: 'translate(-50%, -50%)'
              }}
            >
              {particle.shape}
            </motion.div>
          ))}
        </Box>
      )}
    </AnimatePresence>
  );
};

const Navbar = ({ onAuthClick }) => {
  const theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { label: 'Home', path: '/', icon: <HomeIcon /> },
    { label: 'About', path: '/about', icon: <InfoIcon /> },
    { label: 'Solutions', path: '/solutions', icon: <BusinessIcon /> },
    { label: 'SDGs', path: '/sdg', icon: <NatureIcon /> },
    { label: 'Contact', path: '/contact', icon: <ContactMailIcon /> },
    { label: 'Carbon Calculator', path: '/carbon-calculator', icon: <CalculateIcon /> }
  ];

  const drawer = (
    <Box
      sx={{
        width: 250,
        height: '100%',
        background: theme.palette.background.paper,
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'text.secondary',
        }}
      >
        <CloseIcon />
      </IconButton>
      <List sx={{ pt: 5 }}>
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <ListItem
              key={item.label}
              component={Link}
              to={item.path}
              onClick={handleDrawerToggle}
              sx={{
                color: isActive ? theme.palette.primary.main : 'text.primary',
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
              }}
            >
              <ListItemIcon sx={{ 
                color: isActive ? theme.palette.primary.main : 'text.secondary',
                minWidth: 40,
              }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.label} 
                sx={{
                  '& .MuiListItemText-primary': {
                    fontWeight: isActive ? 600 : 400,
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        backgroundColor: 'white', 
        boxShadow: 1,
        zIndex: theme.zIndex.drawer + 1
      }}
    >
      <Container maxWidth="lg">
        <Toolbar 
          sx={{ 
            justifyContent: 'space-between', 
            px: { xs: 1, sm: 2 },
            minHeight: { xs: '56px', sm: '64px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <motion.div
              onHoverStart={() => setIsLogoHovered(true)}
              onHoverEnd={() => setIsLogoHovered(false)}
              style={{ position: 'relative' }}
            >
              <Link to="/">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Box
                    component="img"
                    src={logo}
                    alt="TGV Logo"
                    sx={{
                      height: 85,
                      width: 'auto',
                      filter: isLogoHovered ? 'brightness(1.1)' : 'none',
                      transition: 'all 0.3s ease'
                    }}
                  />
                </motion.div>
              </Link>
              <Particles isActive={isLogoHovered} />
            </motion.div>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {navItems.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Button
                  key={item.label}
                  component={Link}
                  to={item.path}
                  sx={{
                    color: isActive ? theme.palette.primary.main : 'text.primary',
                    opacity: isActive ? 1 : 0.87,
                    fontWeight: isActive ? 600 : 500,
                    textTransform: 'none',
                    fontSize: '1rem',
                    px: 2,
                    position: 'relative',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.2s ease-in-out'
                    },
                    '&::after': isActive ? {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 8,
                      right: 8,
                      height: 3,
                      borderRadius: '3px 3px 0 0',
                      backgroundColor: theme.palette.primary.main,
                    } : {}
                  }}
                >
                  {item.label}
                </Button>
              );
            })}
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ color: 'text.primary' }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better mobile performance
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 250,
            boxShadow: '0 0 20px rgba(0,0,0,0.1)',
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;