import React, { useEffect } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, useTheme } from '@mui/material';
import SEO from '../components/SEO';

const sdgs = [
  {
    number: 1,
    title: "No Poverty",
    description: "End poverty in all its forms everywhere",
    color: "#E5243B"
  },
  {
    number: 2,
    title: "Zero Hunger",
    description: "End hunger, achieve food security and improved nutrition",
    color: "#DDA63A"
  },
  {
    number: 3,
    title: "Good Health and Well-being",
    description: "Ensure healthy lives and promote well-being for all",
    color: "#4C9F38"
  },
  {
    number: 4,
    title: "Quality Education",
    description: "Ensure inclusive and equitable quality education",
    color: "#C5192D"
  },
  {
    number: 5,
    title: "Gender Equality",
    description: "Achieve gender equality and empower all women and girls",
    color: "#FF3A21"
  },
  {
    number: 6,
    title: "Clean Water and Sanitation",
    description: "Ensure availability and sustainable management of water",
    color: "#26BDE2"
  },
  {
    number: 7,
    title: "Affordable and Clean Energy",
    description: "Ensure access to affordable, reliable, sustainable energy",
    color: "#FCC30B"
  },
  {
    number: 8,
    title: "Decent Work and Economic Growth",
    description: "Promote sustained, inclusive economic growth",
    color: "#A21942"
  },
  {
    number: 9,
    title: "Industry, Innovation and Infrastructure",
    description: "Build resilient infrastructure, promote inclusive industrialization",
    color: "#FD6925"
  },
  {
    number: 10,
    title: "Reduced Inequalities",
    description: "Reduce inequality within and among countries",
    color: "#DD1367"
  },
  {
    number: 11,
    title: "Sustainable Cities and Communities",
    description: "Make cities inclusive, safe, resilient and sustainable",
    color: "#FD9D24"
  },
  {
    number: 12,
    title: "Responsible Consumption and Production",
    description: "Ensure sustainable consumption and production patterns",
    color: "#BF8B2E"
  },
  {
    number: 13,
    title: "Climate Action",
    description: "Take urgent action to combat climate change",
    color: "#3F7E44"
  },
  {
    number: 14,
    title: "Life Below Water",
    description: "Conserve and sustainably use the oceans and marine resources",
    color: "#0A97D9"
  },
  {
    number: 15,
    title: "Life on Land",
    description: "Protect, restore and promote sustainable use of terrestrial ecosystems",
    color: "#56C02B"
  },
  {
    number: 16,
    title: "Peace, Justice and Strong Institutions",
    description: "Promote peaceful and inclusive societies for sustainable development",
    color: "#00689D"
  },
  {
    number: 17,
    title: "Partnerships for the Goals",
    description: "Strengthen the means of implementation and revitalize partnerships",
    color: "#19486A"
  }
];

const SDGPage = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO page="sdg" />
      <Box sx={{ 
        py: { xs: 4, md: 8 },
        px: { xs: 2, md: 0 },
        position: 'relative'  
      }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center" 
            sx={{ 
              mb: { xs: 3, md: 6 }, 
              color: theme.palette.primary.main,
              fontSize: { xs: '2.5rem', md: '3.75rem' }
            }}>
            Sustainable Development Goals
          </Typography>

          <Typography 
            variant="h5" 
            component="h2" 
            gutterBottom 
            align="center" 
            sx={{ 
              mb: { xs: 4, md: 8 }, 
              maxWidth: '800px', 
              mx: 'auto',
              px: { xs: 2, md: 0 },
              fontSize: { xs: '1.25rem', md: '1.5rem' }
            }}>
            Our commitment goes beyond carbon emissions tracking. We're aligned with the UN's 17 Sustainable Development Goals 
            to create a more sustainable and equitable future.
          </Typography>

          <Box sx={{ mb: { xs: 4, md: 8 } }}>
            <Typography variant="h4" gutterBottom sx={{ 
              mb: 4,
              fontSize: { xs: '1.75rem', md: '2.125rem' }
            }}>
              Our Impact Beyond Carbon
            </Typography>
            <Typography variant="body1" paragraph sx={{ mb: 3 }}>
              While carbon emissions tracking is crucial, our platform takes a holistic approach to sustainability. 
              We help organizations:
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Track Multiple Metrics
                    </Typography>
                    <Typography variant="body2">
                      Monitor water usage, waste reduction, and social impact alongside carbon emissions.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Align with SDGs
                    </Typography>
                    <Typography variant="body2">
                      Map your sustainability initiatives to specific SDG targets and track progress.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Drive Real Change
                    </Typography>
                    <Typography variant="body2">
                      Get actionable insights and recommendations to improve your sustainability performance.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h4" gutterBottom sx={{ 
            mb: 4,
            fontSize: { xs: '1.75rem', md: '2.125rem' }
          }}>
            The 17 Goals
          </Typography>
          <Grid container spacing={3}>
            {sdgs.map((sdg) => (
              <Grid item xs={12} sm={6} md={4} key={sdg.number}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4
                    },
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box sx={{ 
                    height: { xs: 100, sm: 120, md: 140 }, 
                    bgcolor: sdg.color, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                  }}>
                    <Typography variant="h2" sx={{ 
                      color: 'white',
                      fontSize: { xs: '2.5rem', sm: '3rem', md: '3.75rem' }
                    }}>
                      {sdg.number}
                    </Typography>
                  </Box>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant="h6" gutterBottom sx={{ 
                      fontSize: { xs: '1rem', sm: '1.25rem' },
                      mb: 1
                    }}>
                      {sdg.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {sdg.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        
        {/* Tree Nation Badge - Mobile Optimized */}
        <Box 
          id="tree-nation-offset-website"
          sx={{ 
            mt: 4,
            mx: 'auto',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            '& iframe': {
              maxWidth: '100%',
              height: { xs: '120px', sm: '140px' },
              border: 'none',
              marginBottom: { xs: 2, md: 0 }
            }
          }} 
        />
      </Box>
    </>
  );
};

export default SDGPage;
