import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import {
  Box,
  Typography,
  Paper,
  Grid,
  useTheme,
  Avatar,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { supabase } from '../lib/supabaseClient';
import ForestIcon from '@mui/icons-material/Forest';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { formatDistanceToNow } from 'date-fns';
import confetti from 'canvas-confetti';

// Environmental impact constants (all values in kg CO2)
const IMPACT_CONSTANTS = {
  // Tree & Carbon
  CARBON_PER_TREE: 22, // kg CO2 per year per tree (conservative estimate)
  
  // Aviation
  DUBAI_LONDON_FLIGHT: 930, // Round trip per passenger
  DUBAI_PARIS_FLIGHT: 880, // Round trip per passenger
  HELICOPTER_TOUR: 120, // 30-minute Dubai helicopter tour
  
  // Transportation
  DUBAI_ABU_DHABI: 26.6, // 133km one-way trip at 0.2kg/km
  DUBAI_MALL_DRIVE: 0.6, // 3km loop at 0.2kg/km
  F1_LAP: 5.2, // One lap at Yas Marina Circuit
  SUPERCAR_HOUR: 12.8, // One hour of supercar driving experience
  
  // Fun Local Activities
  DESERT_SAFARI: 8.5, // Per person desert safari experience
  WATER_PARK_VISIT: 4.2, // Per person water park visit
  SKI_DUBAI_SESSION: 15.6, // Per person indoor skiing session
  
  // Building & Infrastructure
  BURJ_ELEVATOR: 0.012, // Per trip to observation deck
  METRO_TRIP: 0.048, // Per passenger per 10km trip
};

const LiveDashboardV2 = () => {
  const theme = useTheme();
  const [treeCount, setTreeCount] = useState(null);
  const [recentPlanters, setRecentPlanters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [milestone, setMilestone] = useState(0);
  const [prevTreeCount, setPrevTreeCount] = useState(0);
  const [isIncreasing, setIsIncreasing] = useState(false);

  const carbonOffset = treeCount ? treeCount * IMPACT_CONSTANTS.CARBON_PER_TREE : 0;

  // Trigger confetti for milestones
  useEffect(() => {
    if (treeCount && treeCount > milestone) {
      // Check for milestone (every 100 trees)
      if (treeCount % 100 === 0) {
        setMilestone(treeCount);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
          colors: ['#2E7D32', '#4CAF50', '#81C784']
        });
      }
    }
  }, [treeCount, milestone]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Effect to handle tree count animation
  useEffect(() => {
    if (treeCount && treeCount > prevTreeCount) {
      setIsIncreasing(true);
      setPrevTreeCount(treeCount);
      
      // Trigger confetti for milestones
      if (treeCount % 100 === 0) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
          colors: ['#2E7D32', '#4CAF50', '#81C784']
        });
      }
      
      // Reset the animation flag after animation duration
      const timer = setTimeout(() => {
        setIsIncreasing(false);
      }, 2000); // Match this with the CSS animation duration
      
      return () => clearTimeout(timer);
    }
  }, [treeCount, prevTreeCount]);

  // Function to generate avatar color based on name and index
  const getAvatarColor = (name, index) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Bright green for most recent
    if (index === 0) {
      return '#2E7D32';
    }

    // Consistent color for older entries
    return '#90A4AE';
  };

  // Function to get initials from name
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  // Load initial data and setup real-time subscription
  useEffect(() => {
    let mounted = true;
    let subscription;

    const fetchData = async () => {
      try {
        // Get tree count
        const { count: treeCount } = await supabase
          .from('tree_plantings')
          .select('*', { count: 'exact', head: true });

        // Get recent planters
        const { data: recentData } = await supabase
          .from('tree_plantings')
          .select('name, created_at')
          .order('created_at', { ascending: false })
          .limit(5);

        if (mounted) {
          setTreeCount(treeCount || 0);
          setRecentPlanters(recentData || []);
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching initial data:', err);
        if (mounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    const setupSubscription = () => {
      subscription = supabase
        .channel('tree_plantings_live')
        .on('postgres_changes', 
          { event: 'INSERT', schema: 'public', table: 'tree_plantings' },
          (payload) => {
            if (mounted) {
              // Update tree count
              setTreeCount(prev => (prev || 0) + 1);

              // Update recent planters with smooth animation
              setRecentPlanters(prev => [{
                name: payload.new.name,
                created_at: payload.new.created_at
              }, ...prev.slice(0, 4)]);
            }
          }
        )
        .subscribe((status) => {
          if (status === 'SUBSCRIBED') {
            console.log('Successfully subscribed to tree plantings');
          }
        });
    };

    fetchData();
    setupSubscription();

    return () => {
      mounted = false;
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  const calculateDetailedImpact = (trees) => {
    const carbonKg = trees * IMPACT_CONSTANTS.CARBON_PER_TREE;
    
    return {
      carbonKg: Math.round(carbonKg),
      dubaiLondonFlights: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_LONDON_FLIGHT * 10) / 10,
      dubaiParisFlights: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_PARIS_FLIGHT * 10) / 10,
      helicopterTours: Math.round(carbonKg / IMPACT_CONSTANTS.HELICOPTER_TOUR),
      f1Laps: Math.round(carbonKg / IMPACT_CONSTANTS.F1_LAP),
      supercarHours: Math.round(carbonKg / IMPACT_CONSTANTS.SUPERCAR_HOUR),
      dubaiAbuDhabiTrips: Math.round(carbonKg / IMPACT_CONSTANTS.DUBAI_ABU_DHABI),
    };
  };

  const getRelevantImpactStatements = (impact) => {
    const statements = [];
    
    if (impact.dubaiLondonFlights >= 0.5) {
      statements.push({
        icon: '✈️',
        primary: `${impact.dubaiLondonFlights} Dubai-London flights`,
        secondary: 'Round trips offset'
      });
    }

    if (impact.helicopterTours >= 1) {
      statements.push({
        icon: '🚁',
        primary: `${impact.helicopterTours} helicopter tours`,
        secondary: 'Dubai aerial experiences'
      });
    }
    
    if (impact.f1Laps >= 10) {
      statements.push({
        icon: '🏎️',
        primary: `${impact.f1Laps} F1 laps`,
        secondary: 'At Yas Marina Circuit'
      });
    }

    // Add more fun local activities comparisons
    const skiSessions = Math.round(impact.carbonKg / IMPACT_CONSTANTS.SKI_DUBAI_SESSION);
    if (skiSessions >= 5) {
      statements.push({
        icon: '⛷️',
        primary: `${skiSessions} Ski Dubai sessions`,
        secondary: 'Indoor skiing experiences'
      });
    }

    const desertSafaris = Math.round(impact.carbonKg / IMPACT_CONSTANTS.DESERT_SAFARI);
    if (desertSafaris >= 3) {
      statements.push({
        icon: '🐪',
        primary: `${desertSafaris} desert safaris`,
        secondary: 'Adventure experiences'
      });
    }

    const waterParkVisits = Math.round(impact.carbonKg / IMPACT_CONSTANTS.WATER_PARK_VISIT);
    if (waterParkVisits >= 5) {
      statements.push({
        icon: '🌊',
        primary: `${waterParkVisits} water park visits`,
        secondary: 'Aquatic adventures'
      });
    }

    if (statements.length < 5 && impact.dubaiParisFlights >= 0.5) {
      statements.push({
        icon: '🗼',
        primary: `${impact.dubaiParisFlights} Dubai-Paris flights`,
        secondary: 'Round trips offset'
      });
    }

    return statements.slice(0, 5);
  };

  if (loading) {
    return (
      <Box sx={{ 
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2 
      }}>
        <LinearProgress sx={{ width: '200px' }} />
        <Typography variant="body1" color="text.secondary">
          Loading dashboard data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e8edf2 100%)',
        pt: { xs: 2, sm: 3 }, 
        pb: { xs: 2, sm: 2 },
        px: { xs: 2, sm: 2 },
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <Box 
        maxWidth="xl" 
        sx={{ 
          mx: 'auto', 
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Header */}
        <Box sx={{ 
          textAlign: 'center', 
          mb: { xs: 1, sm: 2 }
        }}>
          <Typography
            variant="h6"
            sx={{
              color: '#666',
              fontWeight: 500,
              fontSize: { xs: '0.8rem', sm: '0.85rem' },
              mb: 0.5
            }}
          >
            Campaign Middle East - Agency of the Year Awards 2024
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: '#2E7D32',
              fontWeight: 600,
              fontSize: { xs: '1.2rem', sm: '1.3rem' }
            }}
          >
            Live Tree Planting Dashboard
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ flex: 1 }}>
          {/* Main Stats */}
          <Grid item xs={12} md={8} sx={{ height: '100%' }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              height: '100%'
            }}>
              {/* Tree Count and Carbon Offset */}
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 1.5, sm: 2 },
                  borderRadius: 2,
                  background: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(10px)',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Grid container spacing={2}>
                  {/* Tree Count */}
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      textAlign: 'center',
                      p: { xs: 1, sm: 1.5 },
                      borderRadius: 2,
                      transition: 'all 0.3s ease',
                      animation: isIncreasing ? 'pulse 2s ease-in-out' : 'none',
                      '@keyframes pulse': {
                        '0%': {
                          transform: 'scale(1)',
                          background: 'rgba(46, 125, 50, 0)',
                        },
                        '50%': {
                          transform: 'scale(1.05)',
                          background: 'rgba(46, 125, 50, 0.1)',
                        },
                        '100%': {
                          transform: 'scale(1)',
                          background: 'rgba(46, 125, 50, 0)',
                        }
                      }
                    }}>
                      <ForestIcon sx={{ 
                        fontSize: { xs: 32, sm: 36 }, 
                        color: '#2E7D32', 
                        mb: 1
                      }} />
                      <Typography variant="h3" sx={{ 
                        fontWeight: 700, 
                        color: '#1a1a1a', 
                        fontSize: { xs: '1.8rem', sm: '2rem' },
                        lineHeight: 1
                      }}>
                        {treeCount === null ? (
                          "Loading..."
                        ) : (
                          <CountUp
                            end={treeCount}
                            duration={2}
                            separator=","
                          />
                        )}
                      </Typography>
                      <Typography variant="h6" sx={{ 
                        color: '#666',
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        fontWeight: 500
                      }}>
                        Trees Planted Tonight
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Carbon Offset */}
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      textAlign: 'center',
                      p: { xs: 1, sm: 1.5 },
                      borderRadius: 2
                    }}>
                      <AcUnitIcon sx={{ 
                        fontSize: { xs: 32, sm: 36 }, 
                        color: '#2E7D32', 
                        mb: 1
                      }} />
                      <Typography variant="h3" sx={{ 
                        fontWeight: 700, 
                        color: '#1a1a1a', 
                        fontSize: { xs: '1.8rem', sm: '2rem' },
                        lineHeight: 1
                      }}>
                        <CountUp
                          end={carbonOffset}
                          duration={2}
                          separator=","
                          suffix=" kg"
                        />
                      </Typography>
                      <Typography variant="h6" sx={{ 
                        color: '#666',
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        fontWeight: 500
                      }}>
                        CO₂ Offset Per Year
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                {/* Environmental Impact */}
                <Typography variant="h6" sx={{ 
                  my: { xs: 1, sm: 1.5 },
                  color: '#2E7D32', 
                  fontWeight: 600, 
                  textAlign: 'center',
                  fontSize: { xs: '0.9rem', sm: '1rem' }
                }}>
                  Environmental Impact
                </Typography>

                <Grid container spacing={1.5} sx={{ flex: 1 }}>
                  {getRelevantImpactStatements(calculateDetailedImpact(treeCount)).slice(0, 4).map((statement, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: { xs: 1, sm: 1.5 },
                          height: '100%',
                          bgcolor: 'rgba(46, 125, 50, 0.04)',
                          border: '1px solid rgba(46, 125, 50, 0.1)',
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          animation: isIncreasing ? 'fadeIn 0.5s ease-out' : 'none',
                          '@keyframes fadeIn': {
                            '0%': {
                              opacity: 0.7,
                              transform: 'translateY(10px)'
                            },
                            '100%': {
                              opacity: 1,
                              transform: 'translateY(0)'
                            }
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography sx={{ 
                            fontSize: { xs: '1.3rem', sm: '1.5rem' },
                            lineHeight: 1,
                            mb: 0.5
                          }}>
                            {statement.icon}
                          </Typography>
                          <Typography sx={{ 
                            fontWeight: 600,
                            fontSize: { xs: '0.8rem', sm: '0.9rem' },
                            lineHeight: 1.2,
                            mb: 0.25
                          }}>
                            {statement.primary}
                          </Typography>
                          <Typography sx={{ 
                            color: 'text.secondary',
                            fontSize: { xs: '0.7rem', sm: '0.8rem' },
                            lineHeight: 1.2
                          }}>
                            {statement.secondary}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Box>
          </Grid>

          {/* Recent Activity */}
          <Grid item xs={12} md={4} sx={{ height: '100%' }}>
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                p: { xs: 1.5, sm: 2 },
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="h5" sx={{ 
                mb: 1.5,
                color: theme.palette.text.primary,
                fontSize: { xs: '0.9rem', sm: '1rem' },
                fontWeight: 600
              }}>
                Live Feed
              </Typography>
              <Box sx={{ flex: 1 }}>
                {recentPlanters.map((planter, index) => (
                  <Box
                    key={planter.created_at}
                    sx={{
                      marginBottom: '1rem',
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '0.75rem'
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 48,
                        height: 48,
                        bgcolor: getAvatarColor(planter.name, index),
                        color: '#fff',
                        fontSize: '1.25rem',
                        fontWeight: 600,
                        border: '3px solid #fff',
                        boxShadow: index === 0 
                          ? '0 4px 14px rgba(76, 175, 80, 0.3)'
                          : '0 4px 12px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      {getInitials(planter.name)}
                    </Avatar>

                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                          mb: 0.5
                        }}
                      >
                        {planter.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {formatDistanceToNow(new Date(planter.created_at), { addSuffix: true })}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Error Snackbar */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LiveDashboardV2;
