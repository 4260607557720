import React from 'react';
import { Helmet } from 'react-helmet-async';
import baseConfig, { seoConfig } from '../config/seo';

const SEO = ({ page }) => {
  const config = seoConfig[page];
  
  if (!config) return null;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{config.title}</title>
      <meta name="description" content={config.description} />
      
      {/* Open Graph Tags */}
      <meta property="og:title" content={`${config.title} | The GreenVantage`} />
      <meta property="og:description" content={config.description} />
      <meta property="og:type" content={baseConfig.openGraph.type} />
      <meta property="og:locale" content={baseConfig.openGraph.locale} />
      <meta property="og:url" content={baseConfig.openGraph.url} />
      <meta property="og:site_name" content={baseConfig.openGraph.siteName} />
      
      {/* Twitter Tags */}
      <meta name="twitter:card" content={baseConfig.twitter.cardType} />
      <meta name="twitter:title" content={`${config.title} | The GreenVantage`} />
      <meta name="twitter:description" content={config.description} />
      <meta name="twitter:site" content={baseConfig.twitter.handle} />
      
      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(config.jsonLd)}
      </script>
    </Helmet>
  );
};

export default SEO;
