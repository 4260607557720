const baseConfig = {
  titleTemplate: '%s | The GreenVantage',
  defaultTitle: 'The GreenVantage - Sustainable Digital Solutions',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://thegreenvantage.com/',
    siteName: 'The GreenVantage'
  },
  twitter: {
    handle: '@thegreenvantage',
    cardType: 'summary_large_image'
  }
};

export const seoConfig = {
  home: {
    title: 'Sustainable Digital Solutions',
    description: "Part of the TMV Group, The GreenVantage focuses on sustainable solutions helping our partners, both at an agency and client direct level, utilize digital and physical products to drive their sustainable messaging.",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "The GreenVantage",
      "url": "https://thegreenvantage.com",
      "description": "Part of the TMV Group, The GreenVantage focuses on sustainable solutions helping partners achieve net zero targets.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Reef Tower - 8th Floor",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "UAE"
      }
    }
  },
  about: {
    title: 'About Us',
    description: "Learn about The GreenVantage's mission to revolutionize digital advertising through sustainable practices and our commitment to helping organizations achieve their net zero targets.",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "mainEntity": {
        "@type": "Organization",
        "name": "The GreenVantage",
        "description": "We assist marketing departments and organizations achieve net zero targets and align to the growing understanding that our industry has a significant role to play in the climate challenge."
      }
    }
  },
  solutions: {
    title: 'Our Solutions',
    description: "Discover how The GreenVantage helps organizations achieve their sustainability goals through innovative digital solutions and strategic partnerships.",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Service",
      "provider": {
        "@type": "Organization",
        "name": "The GreenVantage"
      },
      "serviceType": "Sustainable Digital Solutions",
      "description": "Comprehensive sustainable digital solutions for marketing departments and organizations."
    }
  },
  contact: {
    title: 'Contact Us',
    description: "Get in touch with The GreenVantage. We are here to help you transform your digital presence with sustainable solutions.",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "mainEntity": {
        "@type": "Organization",
        "name": "The GreenVantage",
        "telephone": "04 425 3300",
        "email": "info@themediavantage.com",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Reef Tower - 8th Floor",
          "addressLocality": "Dubai",
          "addressRegion": "Dubai",
          "addressCountry": "UAE"
        }
      }
    }
  },
  sdg: {
    title: 'Sustainable Development Goals',
    description: "Explore how The GreenVantage aligns with and supports the UN's Sustainable Development Goals through our digital solutions and practices.",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Sustainable Development Goals",
      "description": "Our commitment to the UN's Sustainable Development Goals and creating a more sustainable future.",
      "publisher": {
        "@type": "Organization",
        "name": "The GreenVantage"
      }
    }
  }
};

export default baseConfig;
